<template>
  <footer class="mt-4">
    <p class="text-muted text-center small">Copyright &copy; 2006 - {{ year }} n3t design</p>
  </footer>
</template>

<script>
import { computed } from 'vue'

export default {
  setup () {
    const year = computed(() => new Date().getFullYear())
    return { year }
  }
}
</script>
