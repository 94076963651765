
import { defineComponent } from 'vue'
import badges from '@/data/badges.json'

export default defineComponent({
  name: 'ExtensionPanel',

  data () {
    return {
      extension: {}
    }
  },

  computed: {
    imageUrl () {
      return require('@/data/' + this.name + '/image.png')
    }
  },

  props: {
    name: String
  },

  mounted () {
    import(`@/data/${this.name}/meta.json`).then((data) => {
      this.extension = data
    })
  },

  methods: {
    badge (name: string): string {
      return badges[name as keyof typeof badges]
    }
  }
})

