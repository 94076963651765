import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ExtensionView from '../views/ExtensionView.vue'
import Error404View from '../views/Error404View.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/extension/:name',
    name: 'Extension',
    component: ExtensionView,
    props: true
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: Error404View
  }
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior (to, from, savedPosition) {
    if (to.name === 'Home' && savedPosition != null) {
      return savedPosition
    }

    // always scroll to top
    return {
      top: 0
    }
  },
  routes
})

export default router
