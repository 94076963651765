
import { defineComponent } from 'vue'
import ExtensionPanel from '@/components/ExtensionPanel.vue'
import HtmlMeta from '@/components/HtmlMeta.vue'
import { searchField } from '@/js/filter-search'

export default defineComponent({
  name: 'HomeView',

  data: () => {
    return {
      extensions: Array<string>()
    }
  },

  components: {
    ExtensionPanel,
    HtmlMeta
  },

  created () {
    const extensions = require.context(
      '@/data/',
      true,
      /meta\.json$/
    )

    extensions.keys().forEach(extension => {
      this.extensions.push(extension.replace(/\.\/(.*)\/meta\.json/, '$1'))
    })
  },

  mounted () {
    searchField('.filter-search')
    // For puppeteer
    document.dispatchEvent(new Event('render-event'))
  },

  methods: {
    schema (): object {
      return {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        url: 'https://n3t.bitbucket.io',
        name: 'n3t Joomla! Extensions',
        description: 'Joomla! plugins, modules and components by n3t.'
      }
    }
  }
})
