
import { defineComponent } from 'vue'
import HtmlMeta from '@/components/HtmlMeta.vue'

export default defineComponent({
  name: 'Error404View',

  components: {
    HtmlMeta
  }
})
