<script>
export default {
  name: 'HtmlMeta',
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    keywords: {
      type: String,
      required: false,
      default: ''
    },
    image: {
      type: String,
      required: false,
      default: ''
    },
    url: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: ''
    },
    schema: {
      type: Object,
      required: false,
      default (rawProps) {
        return {
          '@context': 'http://schema.org',
          '@type': 'WebSite',
          url: '',
          name: '',
          description: ''
        }
      }
    }
  },
  watch: {
    title: {
      immediate: true,
      handler () {
        if (this.title !== 'n3t Joomla! Extensions') {
          document.title = this.title + ' - n3t Joomla! Extensions'
          document.getElementsByTagName('title').item(0).text = this.title + ' - n3t Joomla! Extensions'
        } else {
          document.title = this.title
          document.getElementsByTagName('title').item(0).text = this.title
        }
        document.getElementById('meta-og-title').setAttribute('content', this.title)
        document.getElementById('meta-og-image-alt').setAttribute('content', this.title)
        document.getElementById('meta-twitter-title').setAttribute('content', this.title)
        document.getElementById('meta-twitter-image-alt').setAttribute('content', this.title)
      }
    },
    description: {
      immediate: true,
      handler () {
        document.getElementById('meta-description').setAttribute('content', this.description)
        document.getElementById('meta-og-description').setAttribute('content', this.description)
        document.getElementById('meta-twitter-description').setAttribute('content', this.description)
      }
    },
    keywords: {
      immediate: true,
      handler () {
        document.getElementById('meta-keywords').setAttribute('content', this.keywords)
      }
    },
    image: {
      immediate: true,
      handler () {
        document.getElementById('meta-og-image').setAttribute('content', 'https://n3t.bitbucket.io' + this.image)
        document.getElementById('meta-twitter-image').setAttribute('content', 'https://n3t.bitbucket.io' + this.image)
      }
    },
    url: {
      immediate: true,
      handler () {
        document.getElementById('meta-og-url').setAttribute('content', this.url)
      }
    },
    type: {
      immediate: true,
      handler () {
        document.getElementById('meta-og-type').setAttribute('content', this.type)
      }
    },
    schema: {
      immediate: true,
      handler () {
        document.getElementById('meta-json-ld').text = JSON.stringify(this.schema)
      }
    }
  },
  render () { return '' }
}
</script>
