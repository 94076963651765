<template>
  <div class="container">
    <AppHeader />

    <div class="row">
      <div class="col-xs-12 col-md-9 mb-4">
        <router-view/>
      </div>

      <div class="col-xs-12 col-md-3">
        <AppRightCol />
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppRightCol from '@/components/AppRightCol.vue'

export default {
  components: {
    AppHeader,
    AppFooter,
    AppRightCol
  }
}
</script>
