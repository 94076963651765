<template>
  <div class="list-group">
    <a href="http://n3t.boards.net" class="list-group-item active">
      <h4 class="list-group-item-heading">Support forum</h4>
      <p class="list-group-item-text">Ask question about extensions, feature requests etc.</p>
    </a>
  </div>

  <div class="card mt-4 mb-4">
    <div class="card-body">
      <h3 class="card-title">Support us</h3>
      <form action="https://www.paypal.com/donate" method="post" target="_top" class="text-center mb-4">
        <input type="hidden" name="business" value="T3DP3UGRHM37S" />
        <input type="hidden" name="no_recurring" value="0" />
        <input type="hidden" name="item_name" value="Development support" />
        <input type="hidden" name="currency_code" value="CZK" />
        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
      </form>

      <p class="text-center">
        <a href="https://www.buymeacoffee.com/n3tcz"><img src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=n3tcz&button_colour=FFDD00&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=ffffff" /></a>
      </p>
    </div>
  </div>

  <h3>News</h3>
  <a class="twitter-timeline" href="https://twitter.com/n3tcz" data-tweet-limit="5" data-chrome="noheader">Tweets by n3tcz</a>
</template>

<script>
export default {
  mounted () {
    const twitterScript = document.createElement('script')
    twitterScript.setAttribute('src', 'https://platform.twitter.com/widgets.js')
    twitterScript.setAttribute('charset', 'utf-8')
    twitterScript.setAttribute('async', '')
    document.head.appendChild(twitterScript)
  }
}
</script>
