function searchNormalize (str: string | null): string {
  if (!str) return ''
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()
}

export function filterSearch (containersSelector: string, keyword: string): void {
  [...document.querySelectorAll(containersSelector + ' .search')]
    .forEach(el => el.classList.remove('found'))
  if (keyword) {
    [...document.querySelectorAll(containersSelector)]
      .forEach(el => el.classList.add('searching'));
    [...document.querySelectorAll(containersSelector + ' .search')]
      .filter(el => searchNormalize(el.textContent).includes(searchNormalize(keyword)))
      .forEach(el => el.classList.add('found'))
    if (document.querySelectorAll(containersSelector + ' .search.found').length) {
      [...document.querySelectorAll(containersSelector)]
        .forEach(el => el.classList.add('found'))
    } else {
      [...document.querySelectorAll(containersSelector)]
        .forEach(el => el.classList.remove('found'))
    }
  } else {
    [...document.querySelectorAll(containersSelector)]
      .forEach(el => el.classList.remove('searching', 'found'))
  }
}

export function searchField (fieldSelector: string): void {
  [...(document.querySelectorAll(fieldSelector))]
    .forEach(el => {
      if (el instanceof HTMLInputElement) {
        const minLength = el.dataset.searchMinlength ?? 1
        const target = el.dataset.searchTarget ?? '.searchable'
        el.addEventListener('input', () => {
          if (el.value.length >= minLength) {
            filterSearch(target, el.value)
          } else {
            filterSearch(target, '')
          }
        })
      }
    })
}

export default searchField
