
import { defineComponent } from 'vue'
import { marked } from 'marked'

export default defineComponent({
  name: 'MarkDown',

  computed: {
    markDown () {
      return marked(this.source)
    }
  },

  props: {
    source: {
      type: String,
      required: true,
      default: ''
    }
  }
})

