
import { defineComponent } from 'vue'
import badges from '@/data/badges.json'
import HtmlMeta from '@/components/HtmlMeta.vue'
import MarkDown from '@/components/MarkDown.vue'
import { Disqus } from 'vue-disqus'

export default defineComponent({
  name: 'ExtensionView',

  components: {
    HtmlMeta,
    MarkDown,
    Disqus
  },

  data: () => {
    return {
      extension: {},
      description: '',
      disqusConfig: {
        identifier: ''
      }
    }
  },

  computed: {
    versionBadge () {
      return 'https://img.shields.io/badge/Latest%20version-' + this.extension['version' as keyof typeof this.extension] + '-informational'
    },
    releaseDateBadge () {
      let releaseDate = this.extension['releaseDate' as keyof typeof this.extension] as string
      releaseDate = releaseDate.replaceAll('-', '--')
      return 'https://img.shields.io/badge/Release%20date-' + releaseDate + '-informational'
    }
  },

  created () {
    this.disqusConfig.identifier = this.name

    import(`@/data/${this.name}/meta.json`).then(data => {
      this.extension = data
    }).catch(() => {
      this.$router.push({ name: 'PageNotFound', params: { pathMatch: this.$route.path.substring(1).split('/') } })
    })

    import(`raw-loader!@/data/${this.name}/description.md`).then((data) => {
      this.description = data.default
    }).catch(() => {
      this.description = ''
    })
  },

  mounted () {
    // For puppeteer
    document.dispatchEvent(new Event('render-event'))
  },

  props: {
    name: {
      type: String,
      required: true
    }
  },

  methods: {
    imageUrl () {
      try {
        return require('@/data/' + this.name + '/image.png')
      } catch (e) {
        return ''
      }
    },
    badge (name: string): string {
      return badges[name as keyof typeof badges]
    },
    schema (): object {
      return {
        '@context': 'http://schema.org',
        '@type': 'WebApplication',
        name: this.extension['name' as keyof typeof this.extension],
        applicationCategory: 'BrowserApplication',
        offers: {
          '@type': 'Offer',
          price: 0
        }
      }
    }
  }
})
